<template>
  <ion-page>
    <ion-content fullscreen class="ion-padding" scroll-y="false">
      <Slides></Slides>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';
import Slides from '@/components/Slides';

export default  {
  name: 'Tab2',
  components: { IonPage, IonContent, Slides }
}
</script>